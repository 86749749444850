import React from 'react'

// css
import "../../Css/Cart.css";

// material icon
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

// redux
import { useSelector } from "react-redux";

const CartView = () => {
    
  const cart = useSelector((state) => state.cartReducer);
    return (
        <>
          <div className="cart__table">
            <p>(Scroll left-right to see the full table)</p>
            <table>
              {/* <thead> */}
              <tr>
                <th>Scrap Name</th>
                <th>Price (Rs)</th>
                <th>Approx. Quantity</th>
                <th>Approx. Total (Rs)</th>
              </tr>
              {/* </thead> */}
              {cart.cartItems.map((eachItem, eachItemIndex) => {
                return (
                  <>
                    {/* <tbody> */}
                    <tr key={eachItemIndex}>
                      <td>{eachItem.name}</td>
                      <td>{eachItem.price}</td>
                      <td>
                        {eachItem.itemQuantity}
                      </td>
                      <td>{eachItem.totalPrice}</td>
                    </tr>
                    {/* </tbody> */}
                  </>

                );
              })}
            </table>
            {/* <h1> Approx. Grand Total : <span>{total}</span></h1> */}
            <h1> Approx. Grand Total : <span>{cart.cartItems.reduce((acc, curr) => acc + curr.totalPrice, 0)} Rs</span></h1>
          </div>
        </>
      );
}

export default CartView