import Modal from "react-modal";
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { apiUrl } from '../../../Private'
import MainFooter from '../../Footer/MainFooter'
import TermFooter from '../../Footer/TermFooter'
import Navbar from '../../Navbar'
import UserProfileSearchbar from '../../UserProfileSearchbar'
import UserProfileNavbar from '../UserProfileNavbar'
import UserAddressCard from './UserAddressCard'

import '../../../Css/UserAddress.css'

const UserAddress = () => {

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  const [userAddress, setUserAddress] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [areaInputData, setAreaInputData] = useState({
    pincode: "",
    city: "",
    address: "",
    addressarea: "",
    landmark: "",
    state: "",
  });

  // open modal
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  // get addresses
  useEffect(() => {
    axios.get(`${apiUrl}/address/get-address-by-ktid/${apiKey['KT_ID']}/`)
      .then((response) => {
        setUserAddress(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isOpen])

  const addArea = async () => {
    const data = new FormData();

    if (!isNaN(areaInputData.pincode) && areaInputData.pincode.length === 6 && areaInputData.state && areaInputData.city && areaInputData.landmark && areaInputData.address && areaInputData.addressarea) {
      data.append("user_id", apiKey['id']);
      data.append("user_type", apiKey['account_type']);
      data.append("name", apiKey['username']);
      data.append("kabadi_techno_id", apiKey['KT_ID']);
      data.append("phone_number", apiKey['mobile_number']);
      data.append("address", areaInputData.address);
      data.append("address_area", areaInputData.addressarea);
      data.append("landmark", areaInputData.landmark);
      data.append("city", areaInputData.city);
      data.append("state", areaInputData.state);
      data.append("pincode", areaInputData.pincode);
      if (userAddress.length === 0)
        data.append("default", "default");
      else
        data.append("default", "not default");

      if (userAddress.length <= 4) {
        const res = await axios.post(`${apiUrl}/address/post-address/`, data, {
          headers: {
            "Content-type": "multipart/form-data"
          }
        })
        console.log(res)
        if (res.request.status === 200) {
          Swal.fire({
            title: "Successfully added area",
            confirmButtonColor: '#56b124',
          })
          setAreaInputData({
            pincode: "",
            city: "",
            address: "",
            addressarea: "",
            landmark: "",
            state: "",
          })
          setIsOpen(!isOpen)
        }
      }
      else {
        Swal.fire({
          title: "You can add only 5 addresses.",
          confirmButtonColor: '#56b124',
        })
        setIsOpen(!isOpen)
      }
    }
    else {
      Swal.fire({
        title: "Enter valid fields. All fields are mandatory.",
        confirmButtonColor: '#56b124',
      })
    }
  }
  
  const deleteArea = async (id) => {

    // If this address is default then make another address default next to it
    // axios.get(`${apiUrl}/address/get-address/${id}/`)
    // .then((res) => {
    //   if(res.data['default'] === "default") {
    //     const data = new FormData();
    //     data.append("address_id", userAddress[1].id);
    //     data.append("name", apiKey['username']);
    //     data.append("phone_number", apiKey['mobile_number']);
    //     data.append("pincode", userAddress[1].pincode);
    //     data.append("address", userAddress[1].address);
    //     data.append("address_area", userAddress[1].address_area);
    //     data.append("landmark", userAddress[1].landmark);
    //     data.append("city", userAddress[1].city);
    //     data.append("state", userAddress[1].state);
    //     data.append("default","default" );
      
    //   axios.post(`${apiUrl}/address/update-address/`, data, {
    //     headers: {
    //       "Content-type": "multipart/form-data"
    //     }
    //   })
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // })

    // delete address
    axios.get(`${apiUrl}/address/delete-address/${id}/`)
      .then((res) => {
        if (res.data['successful'] !== "" && res.request.status === 202)
          Swal.fire({
            title: "Successfully deleted address",
            confirmButtonColor: "#56b124"
          })
      }).catch((err) => {
        console.log(err)
      })
  }

  // get input value
  const getInputValue = (e) => {
    setAreaInputData({ ...areaInputData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Navbar />

      <UserProfileSearchbar />

      <UserProfileNavbar />

      <div className="similar__section">
        <h1 className="similar__section__heading">Set Your Addresses</h1>
        <div className="address_cards">
          <div className="add__address__card" onClick={openModal}>
            <span>&#43;</span>
            <p>Add Address</p>
          </div>
          {userAddress.length !== 0 ? (
            userAddress.map((eachData, eachDataIndex) => {
              return (
                <UserAddressCard
                  key={eachDataIndex}
                  pincode={eachData.pincode}
                  address_id={eachData.id}
                  state={eachData.state}
                  city={eachData.city}
                  area={eachData.address}
                  address_area={eachData.address_area}
                  landmark={eachData.landmark}
                  default={eachData.default}
                deleteArea={deleteArea.bind(this, eachData.id)}
                />
              );
            })
          ) : (
            <p>No Addresses available here</p>
          )}
        </div>
      </div>

      <Modal
        className="modal__content"
        overlayClassName="modal__overlay"
        isOpen={isOpen}
        ariaHideApp={false}
      >
        <h1>Set Address</h1>
        <input
          type="text"
          required
          placeholder="Enter Pincode"
          name="pincode"
          value={areaInputData.pincode}
          onChange={getInputValue}
        />
        <input
          type="text"
          required
          placeholder="Enter Address"
          name="address"
          value={areaInputData.address}
          onChange={getInputValue}
        />
        <input
          type="text"
          required
          placeholder="Enter Address Area"
          name="addressarea"
          value={areaInputData.addressarea}
          onChange={getInputValue}
        />
        <input
          type="text"
          required
          placeholder="Enter Landmark"
          name="landmark"
          value={areaInputData.landmark}
          onChange={getInputValue}
        />
        <input
          type="text"
          required
          placeholder="Enter City"
          name="city"
          value={areaInputData.city}
          onChange={getInputValue}
        />
        <input
          type="text"
          required
          placeholder="Enter State"
          name="state"
          value={areaInputData.state}
          onChange={getInputValue}
        />
        <div>
          <button onClick={openModal} className="add__area__button">
            Cancel
          </button>
          <button onClick={addArea} className="add__area__button">
            Add Your Address
          </button>
        </div>
      </Modal>

      <MainFooter />

      <TermFooter />
    </>
  )
}

export default UserAddress