import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
// import GoogleLogin from "react-google-login";

// material ui component
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// component
import Navbar from "../Navbar";
import LeftBanner from "../AuthPageBanner/LeftBanner";
import TermFooter from "../Footer/TermFooter";

// css
import "../../Css/Auth.css";

// api url
import { apiUrl } from "../../Private";


// redux
// import { useDispatch } from "react-redux";
// import { stepReducerActions } from "../../Redux/stepReducer";

const SignIn = () => {

  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  // show hide password
  const showHidePassword = () => {
    setShowPassword(!showPassword);
  };

  //::::::::::::::::::::::::::MINE:::::::::::::::::::::::
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [tabBtn, setTabBtn] = useState('customer');

  const login = async (e,usertype) =>  {
    e.preventDefault();

    if (inputEmail !== "") {
        let user = {
          "email": inputEmail,
          "password": inputPassword
        };

        //const usertype = tabBtn;

        await axios.post(`${apiUrl}/v3/api/sign_in/${usertype}/` , user)
        .then((res) => {

          // account doesn't exists
          if(res.data.msg === "Account Does Not Exist.") {
            Swal.fire({
              title: "Account doesn't exist. Signup first.",
              confirmButtonColor: "#56b124"
            })
          }
          else if(res.data.msg === "Your account is not verified yet. Plz verify it first.") {

            // Logic for sending activation link
            axios.post(`${apiUrl}/v3/api/regenerate-verification-email/${tabBtn}/`, {
              email: inputEmail
            })
              .then((res) => {
                console.log(res)
                Swal.fire({
                  title: "Account exist but email is not verified. Activation link sent to your mail. Please verify your email",
                  confirmButtonColor: "#56b124"
                })
              }).catch((err) => {
                console.log(err)
              })
          }
          else if(res.data.msg === "Incorrect password") {
            Swal.fire({
              title: "Invalid Credentials",
              confirmButtonColor: "#56b124"
            })
          }
          else {
            localStorage.setItem("KTMauth", JSON.stringify(res.data));
            const infos = JSON.parse(localStorage.getItem("KTMauth"));
            if(infos["account_type"] === "Personal" || infos["account_type"] === "Organization")
              history.push('/sell');
            else if(infos["account_type"] === "Recycler" || infos["account_type"] === "Kabadi" || infos["account_type"] === "Collector")
              history.push('/dealer/home');

          }
        }).catch((err) => {
          console.log(err)          
          Swal.fire({
            title: "Login Error!!",
            confirmButtonColor: "#56b124"
          })
          .then(() => {
            window.location.reload();
          });
        })
      }

  }
  //::::::::::::::::::::::::::MINE:::::::::::::::::::::::

  return (
    <>
      <Navbar />

      <div className="auth__section">
        <LeftBanner />

        <div className="section">
          <h1>Sign In</h1>

          <form className="form">
            <div className="signin__type__btns">
              <button
                className={`signin__type ${'customer' === tabBtn ? 'active' : ''}`}
                key={`customer Button`}
                onClick={() => setTabBtn('customer')}
              >
                Customer
              </button>
              <button
                className={`signin__type ${'dealer' === tabBtn ? 'active' : ''}`}
                key={`dealer Button`}
                onClick={() => setTabBtn('dealer')}
              >
                Dealer
              </button>
            </div>
            <TextField
              className="input"
              type="email"
              label="Email ID"
              variant="outlined"
              onChange={(e) => setInputEmail(e.target.value)}
              name="email"
              value={inputEmail}
              required
            />
            <FormControl variant="outlined" className="form__control">
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                required
                label="Password"
                className="input"
                type={showPassword ? "text" : "password"}
                value={inputPassword}
                name="password"
                onChange={(e) => setInputPassword(e.target.value)}
                endAdornment={
                  <InputAdornment>
                    <IconButton onClick={showHidePassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <NavLink
              className="forgot__password__link"
              to="/forget_password"
            >
              Forgot Password?
            </NavLink>
            {/* <div className="form__btns"> */}
            {/* <button className="form__button" type="submit" value="customer" name="customer" onClick={(e) => { login(e,"customer"); }}>
              Sign In Customer
            </button>
            <button className="form__button" type="submit" value="dealer" name="dealer"  onClick={(e) => { login(e,"dealer"); }}>
              Sign In Dealer
            </button> */}
            <button className="form__button" type="submit" onClick={(e) => { login(e,tabBtn); }}>
              Sign In
            </button>
            {/* </div> */}
          </form>
          {/* <p>Or connect with</p>

          <div className="auth">
            <GoogleLogin
              clientId="839555905156-qpenbug205f1mu5sftdu8skmhmh5pgn9.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  className="auth__button"
                  onClick={() => {
                    renderProps.onClick();
                  }}
                  disabled={renderProps.disabled}
                >
                  <img
                    src="https://image.flaticon.com/icons/png/128/281/281764.png"
                    alt="google-icon"
                  />
                </button>
              )}
              buttonText="Login"
              onSuccess={(response) => responseGoogle(response)}
              onFailure={(response) => responseGoogle(response)}
              cookiePolicy={"single_host_origin"}
            />
          </div> */}
        </div>
      </div>

      <TermFooter />
    </>
  );
};

export default SignIn;





































// import { Fragment, useState } from 'react';
// import { Link } from 'react-router-dom';
// import classes from './SignIn.module.css';

// import { apiUrl } from '../../Private';

// const SignIn = () => {

//     const [inputEmail, setInputEmail] = useState("");
//     const [inputPassword, setInputPassword] = useState("");

//     async function login(e) {

//         e.preventDefault();

//         console.log(inputEmail);
//         console.log(inputPassword);

//         try {

//             let user = {
//                 "email": inputEmail,
//                 "password": inputPassword
//             };

//         let result = await fetch(`${apiUrl}/user_login`, {
//             method:'POST',
//             headers:{
//                 "Content-Type":"application/json",
//                 "Accept":'application/json'
//             },
//             body:JSON.stringify(user)
//         });

//         result = await result.json();
//         localStorage.setItem("KTMauth", JSON.stringify(result));
//         console.log("success");

//     }catch(err) {
//         console.log(err);
//     };
//     }

//     const cls = `line-1 anim-typewriter`;
//     return (
//         <Fragment>
//             <div id={classes.maincontent}>
//                 <section id={classes.home}>
//                     <hgroup className={cls}>"Be a part of solution, <br />not a part of pollution."</hgroup>
//                     <button className={classes.btn} id={classes.signup}><Link to="/signin/signup">Sign Up</Link></button>
//                 </section>
//                 <div className={classes.sidebar}>
//                     <h1>Sign-In</h1>
//                     <div className={classes.form}>
//                         {/* <label for="email_id"></label> */}
//                         <input type="email" name="email_id" id={classes.email_id} placeholder="Email ID*" onChange={(e) => setInputEmail(e.target.value)} />
//                         <br />
//                         {/* <label for="password"></label> */}
//                         <input type="password" name="password" id={classes.password} placeholder="Password" onChange={(e) => setInputPassword(e.target.value)} />
//                         <br />
//                         <Link to="/signin/forgotpassword">Forgot Password?</Link>
//                         <br />
//                         <button className={classes.btn} id={classes.signin} type="submit" onClick={login}>Sign In</button>
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     );
// }

// export default SignIn;