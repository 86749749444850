import React, { useState, useEffect } from "react";
import axios from "axios";

// component
import Navbar from "../../Navbar";
import UserProfileSearchbar from "../../UserProfileSearchbar";
import UserProfileNavbar from "../UserProfileNavbar";
import UserPickupCard from "./UserPickupCard";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// css
import "../../../Css/UserDealerPickup.css";
import "../../../App.css";

// api url
import { apiUrl } from "../../../Private";

const UserPickup = () => {
  const [pickupData, setPickupData] = useState([]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // get pickup data
  useEffect(() => {
    axios.get(`${apiUrl}/api/customer/confirm_orders/${apiKey['id']}/`)
      .then((res) => {
        setPickupData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Navbar />

      <UserProfileSearchbar />

      <UserProfileNavbar />

      <div className="user__dealer__pickup__section similar__section">
        <h1 className="similar__section__heading">Your Pickup</h1>

        <div className="user__dealer__pickup">
          {pickupData.length !== 0 ? (
            pickupData.map((eachData) => {
              // if(eachData.status !== 'Cancelled by Customer')
              return (
                <UserPickupCard
                  key={eachData.id}
                  uniqueId={eachData.id}
                  total_cart_items={eachData.total_cart_items}
                  date={eachData.created_at}
                  total_amount={eachData.total_amount}
                  status={eachData.status}
                  order_no={eachData.order_number}
                />
              );
            })
          ) : (
            <p>No pickup data available here</p>
          )}
        </div>
      </div>

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default UserPickup;
