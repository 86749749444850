import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

// css
import "../../../Css/UserDealerProfile.css";

// component
import DealerProfileSearchbar from "../DealerProfileSearchbar";
import DealerProfileNavbar from "../DealerProfileNavbar";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// material icon
import DescriptionIcon from '@mui/icons-material/Description';
import CropFreeIcon from '@mui/icons-material/CropFree';

// image
import customer__profile__img from "../../../Image/customer__profile__img.PNG";

// api url
import { apiUrl } from "../../../Private";

const DealerProfile = () => {
  const [dealerData, setDealerData] = useState({"ProfilePic" : customer__profile__img});

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get dealer data
  useEffect(() => {
    axios.get(`${apiUrl}/v3/api/view-profile/dealer/${apiKey['tokens']['refresh']}`)
      .then((response) => {
        setDealerData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      {dealerData !== undefined ? (
        <div className="user__dealer__profile">
          <div className="left__side">
            <img src={customer__profile__img} alt="" />
            {/* <img src={dealerData.ProfilePic} alt="" /> */}
            <NavLink
              className="user__dealer__profile__edit__link"
              to="/dealer/profile/profileedit"
            >
              Edit
            </NavLink>
          </div>
          <div className="right__side">
            {/* <p>
              Name : <span>{dealerData.username}</span>
            </p> */}
            <p>
              Email ID : <span>{dealerData.email}</span>
            </p>
            <p>
              Mobile Number : <span>{dealerData.mobile_number}</span>
            </p>
            <p>
              Account Type : <span>{dealerData.account_type}</span>
            </p>
            <p>
              Address: <Link className="view__address">View your address</Link>
            </p>

            <div className="document__qr">
              <NavLink
                className="user__dealer__profile__document__qr__link"
                exact
                to="/dealer/settings/documentupload"
              >
                <DescriptionIcon />
                Documents
              </NavLink>
              <NavLink
                className="user__dealer__profile__document__qr__link"
                to="/dealer/profile/qr"
              >
                <CropFreeIcon />
                QR Code
              </NavLink>
            </div>
          </div>
        </div>
      ) : null}

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerProfile;
