import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

// component
import DealerProfileSearchbar from "../../DealerProfileSearchbar";
import DealerProfileNavbar from "../../DealerProfileNavbar";
import DealerAreaCard from "./DealerAreaCard";
import MainFooter from "../../../Footer/MainFooter";
import TermFooter from "../../../Footer/TermFooter";

// css
import "../../../../Css/DealerArea.css";
import "../../../../App.css";

// dealer area data
// import { DealerAreaData } from "./DealerAreaData";
import { apiUrl } from "../../../../Private";

const DealerArea = () => {
  const [dealerAreaData, setDealerAreaData] = useState([]);
  const [areaInputData, setAreaInputData] = useState({
    pincode: "",
    state: "",
    city: "",
    area: "",
  });
  const [priceData, setPriceData] = useState([])
  const [numberOfPincodesAllowed, setNumberOfPincodesAllowed] = useState(0)
  let pincodes = []

  const history = useHistory();
  const apiKey = JSON.parse(localStorage.getItem('KTMauth'))

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get number of Pincodes allowed to update
  useEffect(() => {
    axios.get(`${apiUrl}/dealer_details/no_of_pincodes/${apiKey['id']}/`)
      .then((res) => {
        setNumberOfPincodesAllowed(res.data.no_of_pincodes)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  // get dealer pincodes 
  useEffect(() => {
    axios.get(`${apiUrl}/dealer_details/get_all_pincodes/${apiKey['id']}/`)
      .then((res) => {
        // let count = Object.keys(res.data).length
        // console.log(count)
        pincodes = []
        for (let x in res.data) {
          if (!isNaN(res.data[x]))
            pincodes.push(res.data[x])
        }
        // const pincodes = [res.data['Pincode 1'] , res.data['Pincode 2'] , res.data['Pincode 3'] , res.data['Pincode 4'] , res.data['Pincode 5']]
        setDealerAreaData(pincodes)
        // console.log(pincodes)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  // get input value
  const getInputValue = (e) => {
    setAreaInputData({ ...areaInputData, [e.target.name]: e.target.value });
  };

  // get area pincodes
  // useEffect(() => {
  //   axios.get(`${apiUrl}/dealer_details/get_all_pincodes/${apiKey['id']}/`)
  // })

  // search area
  const searchArea = async () => {
    if (areaInputData.pincode !== "") {
      await axios
        .get(`https://api.postalpincode.in/pincode/${areaInputData.pincode}`)
        .then((response) => {
          if (response.data[0].PostOffice === null) {
            Swal.fire({
              title: "Check the entered pincode",
              confirmButtonColor: "#56b124",
            });
          } else {
            setAreaInputData({
              ...areaInputData,
              state: response.data[0].PostOffice[0].State,
              city: response.data[0].PostOffice[0].District,
              area: response.data[0].PostOffice[0].Block,
            });
          }
        });
    } else {
      Swal.fire({
        title: "Add a pincode first",
        confirmButtonColor: "#56b124",
      });
    }
  };

  // add area
  const addArea = () => {
    if (
      areaInputData.pincode !== "" &&
      areaInputData.state !== "" &&
      areaInputData.city !== "" &&
      areaInputData.area !== ""
    ) {
      setAreaInputData({
        pincode: "",
        state: "",
        city: "",
        area: "",
      });
      const count = dealerAreaData.length;
      console.log(dealerAreaData)
      console.log(count)
      if (count >= 5) {
        const requestdata = new FormData();
        requestdata.append("dealer_id", apiKey['id'])
        requestdata.append("addrequest", areaInputData.pincode)
        axios.post(`${apiUrl}/dealer_details/request_to_add_pincodes/`, requestdata, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res) => {
          console.log(res.data['Not Acceptable'])
          if (res.data['addrequest'])
            Swal.fire({
              title: "Request to new pincode sent, Please wait while admin approves",
              confirmButtonColor: "#56b124",
            });
          else
            Swal.fire({
              title: "Can't request for more pincodes, Please wait while admin approves your previous request",
              confirmButtonColor: "#56b124",
            });
          // history.push('/dealer/settings')
        }).catch((err) => {
          console.log(err)
        })
      }
      else {
        const data = new FormData();
        data.append("dealer_id", apiKey['id'])
        let i = 0
        let ending
        let flag = 1
        if (count < numberOfPincodesAllowed)
          ending = count
        else ending = numberOfPincodesAllowed
        for (i = 0; i < ending; i++) {
          if (dealerAreaData[i] !== areaInputData.pincode) {
            data.append(`pincode${i + 1}`, dealerAreaData[i])
          }
          else {
            Swal.fire({
              title: "This pincode has already been added.",
              confirmButtonColor: "#56b124",
            })
            flag = 0
          }
        }
        if (flag === 1)
          data.append(`pincode${i + 1}`, areaInputData.pincode)
        else i--;

        for (let j = i + 2; j <= 10; j++)
          data.append(`pincode${j}`, "")
        axios.post(`${apiUrl}/dealer_details/update_pincodes/`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((res) => {
          if (res.data['unsuccessful'] === "The dealer is not registered in pincodes table yet") {
            axios.post(`${apiUrl}/dealer_details/add_pincodes/`, data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(() => {
              Swal.fire({
                title: "Pincodes added successfully",
                confirmButtonColor: "#56b124",
              });
            })
          }
          Swal.fire({
            title: "Area added successfully",
            confirmButtonColor: "#56b124",
          });
          history.push("/dealer/settings");
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  };

  // This data will be used to delete the prices which are set to the pincode in pricelist database table
  useEffect(() => {
    axios.get(`${apiUrl}/dealer_details/get-price/${apiKey['id']}/`)
      .then((res) => {
        setPriceData(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [dealerAreaData])

  // delete area
  const deleteArea = (index) => {
    // const newDealerAreaData = [...dealerAreaData];
    // newDealerAreaData.splice(index, 1);
    // setDealerAreaData(newDealerAreaData);

    let priceDataToBeDeleted = priceData.filter((eachPriceData, eachPriceDataIndex) => {
      return eachPriceData.pincode === dealerAreaData[index]
    })

    for (let i = 0; i < priceDataToBeDeleted.length; i++) {
      const data = new FormData();
      data.append("dealer", apiKey['id'])
      data.append("subcategory", priceDataToBeDeleted[i].subcategory)
      data.append("pincode", dealerAreaData[index])

      axios.post(`${apiUrl}/dealer_details/delete-price/`, data, {
        headers: {
          "Content-type": "multipart/form-data",
        }
      })
        .then((res) => {
          Swal.fire({
            title: "successfully removed",
          })
        })
        .catch((err) => {
          console.log(err)
          Swal.fire({
            title: "successfully removed",
            confirmButtonColor: "green",
          })
        })
    }

    // updating pincodes
    const data = new FormData();
    data.append("dealer_id", apiKey['id'])
    let i, k
    for (i = 0, k = 0; i < dealerAreaData.length - 1; i++, k++) {
      if (i === index)
        k++
      data.append(`pincode${i + 1}`, dealerAreaData[k])
    }

    // let maxi
    // if (numberOfPincodesAllowed <= 5)
    //   maxi = 5
    // else maxi = numberOfPincodesAllowed
    for (let j = i + 1; j <= 10; j++)
      data.append(`pincode${j}`, "")

    axios.post(`${apiUrl}/dealer_details/update_pincodes/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(() => {
      Swal.fire({
        title: "Pincodes updated successfully",
        confirmButtonColor: "#56b124",
      });
      history.push('/dealer/settings/')
    }).catch((err) => {
      console.log(err)
    })

    const newDealerAreaData = [...dealerAreaData];
    newDealerAreaData.splice(index, 1);
    setDealerAreaData(newDealerAreaData);
  };

  // useEffect(() => {
  //   const data = new FormData();
  //   data.append("dealer_id", apiKey['id'])
  //   let i
  //   for (i = 0; i < dealerAreaData.length; i++)
  //     data.append(`pincode${i + 1}`, dealerAreaData[i])

  //   for (let j = i + 1; j <= 5; j++)
  //     data.append(`pincode${j}`, "")

  //   axios.post(`${apiUrl}/dealer_details/update_pincodes/`, data, {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   }).then(() => {
  //     Swal.fire({
  //       title: "Pincodes updated successfully",
  //       confirmButtonColor: "#56b124",
  //     });
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // }, [dealerAreaData])

  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      <div className="dealer__area similar__section">
        <h1 className="similar__section__heading">Set Your Area</h1>

        <div className="area__form" onSubmit={addArea}>
          <input
            type="text"
            required
            placeholder="Enter Your Pincode"
            name="pincode"
            value={areaInputData.pincode}
            onChange={getInputValue}
          />
          <button className="search__area__button" onClick={searchArea}>
            Search
          </button>
          <p>
            State : <span>{areaInputData.state}</span>
          </p>
          <p>
            City : <span>{areaInputData.city}</span>
          </p>
          <p>
            Area : <span>{areaInputData.area}</span>
          </p>
          <button onClick={addArea} className="add__area__button">
            Add Your Area
          </button>
        </div>

        <div className="add__area">
          <h1>Added Area</h1>
          <div>
            {dealerAreaData.length !== 0 ? (
              dealerAreaData.map((eachData, eachDataIndex) => {
                // if(!isNaN(eachData))
                return (
                  <DealerAreaCard
                    key={eachDataIndex}
                    pincode={eachData}
                    // state={eachData.state}
                    // state="stateRaj"
                    // city="{eachData.city}"
                    // area="{eachData.area}"
                    deleteArea={deleteArea.bind(this, eachDataIndex)}
                  />
                );
              })
            ) : (
              <p>No Area available here</p>
            )}
          </div>
        </div>
      </div>

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerArea;
