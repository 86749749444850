import React, { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

// css
import "../../Css/SellItemCard.css";

// React - toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux
import { useDispatch, useSelector } from "react-redux";
import { cartReducerActions } from "../../Redux/cartReducer";
import { apiUrl } from "../../Private";

const SellItemCard = (props) => {
  const [inputValue, setInputValue] = useState("");
  const cart = useSelector((state) => state.cartReducer)

  const dispatch = useDispatch();
  const history = useHistory();

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // add to cart
  const addToCart = () => {

    if (apiKey) {
      if (inputValue !== "" && Number(inputValue) > 0) {
        // Cart is empty (only total amount etc returned in cartData)
        if(cart.cartItems.length === 0)
        {
          dispatch(
            cartReducerActions.add({
              id: props.price_id,
              name: props.name,
              img: props.img,
              dealer: props.dealer,
              price: props.price,
              pincode: props.pincode,
              subcategory_id: props.subcategory_id,
              itemQuantity: inputValue,
              totalPrice: Number(inputValue) * props.price,
              gst: props.gst,
              percentage: props.percentage,
              unit: props.unit
            })
          );
          toast.success('Added to cart!', {
            position: "top-right",
            theme: "light",
          });
        }
        else if (props.dealer === cart.cartItems[0].dealer && props.pincode === cart.cartItems[0].pincode) {

          // ------------------------
          dispatch(
            cartReducerActions.add({
              id: props.price_id,
              name: props.name,
              img: props.img,
              dealer: props.dealer,
              price: props.price,
              pincode: props.pincode,
              subcategory_id: props.subcategory_id,
              itemQuantity: inputValue,
              totalPrice: Number(inputValue) * props.price,
              gst: props.gst,
              percentage: props.percentage,
              unit: props.unit
            })
          );
          toast.success('Added to cart!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error('Dealer pickup pincode is different from the previous dealer pincode of your cart items. To add this scrap item, please clear your cart.', {
            position: "top-right",
            theme: "light",
          });
        }
      }
      else {
        Swal.fire({
          title: "Add a quantity before add to cart",
          confirmButtonColor: "#56b124",
        });
      }
      setInputValue("");
    } else {
      Swal.fire({
        title: "Please Signin to add to cart",
        confirmButtonColor: "#56b124",
      });
      history.push("/signin");
    }
  };

  return (
    <div className="sell__item__card">
      <img src={`${apiUrl}/${props.img}`} alt="" />
      <div className="description">
        <h1>{props.name}</h1>
        <p>
          Price : <span>{props.price} Rs/Kg</span>
        </p>
        <input
          type="number"
          placeholder="Quantity in Kg"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <button onClick={addToCart}>Add to cart</button>
      </div>
    </div>
  );
};

export default SellItemCard;
