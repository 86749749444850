import React, { useState, useEffect } from "react";

// css
import "../../../Css/DealerHome.css";
import "../../../App.css";

// component
import DealerProfileSearchbar from "../DealerProfileSearchbar";
import DealerProfileNavbar from "../DealerProfileNavbar";
import DealerHomeCard from "./DealerHomeCard";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";
import axios from "axios";
import { apiUrl } from "../../../Private";

// api url
// import { apiUrl } from "../../../Private";

const DealerHome = () => {
  const [pickupData, setPickupData] = useState([]);
  const [homeData, setHomeData] = useState([
    { title: "Total Pickup", number: "0" },
    { title: "Total Category", number: "0" },
    { title: "Total Successful", number: "0" },
    { title: "Total Cancel", number: "0" },
    { title: "Total Today Pickup", number: "0" },
  ]);
  let x = 0;
  let t = 0;
  let total = 0;

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // get pickup data
  useEffect(() => {
    axios.get(`${apiUrl}/api/dealer/confirm_orders/${apiKey['id']}/`)
      .then((res) => {
        setPickupData(res.data);
        res.data.map(eachData => {
          if (eachData.status === 'Cancelled by Dealer' || eachData.status === 'Cancelled by Customer')
            x++;
          else if (eachData.status === 'Accepted' && eachData.created_at.slice(0, 10) === getDate)
            t++;
          total++;
        })
        
        console.log(x)
        console.log(total)
        console.log(t)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Update home data variables
  useEffect(() => {
    const updateHomeData = homeData.map(eachTitle => {
      if (eachTitle.title === "Total Cancel")
        return {
          ...homeData,
          title: "Total Cancel",
          number: x,
        };
      else if (eachTitle.title === "Total Pickup")
        return {
          ...homeData,
          title: "Total Pickup",
          number: total,
        };
      else if (eachTitle.title === "Total Today Pickup")
        return {
          ...homeData,
          title: "Total Today Pickup",
          number: t,
        };
      else return eachTitle;
    })
    setHomeData(updateHomeData)

  }, [])

  // Get cancel count
  // useEffect(() => {
  //   let x = 0;
  //   pickupData.map(eachData => {
  //     if (eachData.status === 'Cancelled by Dealer' || eachData.status === 'Cancelled by Customer')
  //       x++;
  //   })

  // }, [])

  const getDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  // Get Total Today's pickups
  // useEffect(() => {
  //   let t = 0;
  //   pickupData.map(eachData => {
  //     if (eachData.status === 'Accepted' && eachData.created_at.slice(0, 10) === getDate)
  //       t++;
  //   })
  //   const updateHomeData = homeData.map(eachTitle => {
  //     if (eachTitle.title === "Total Today Pickup")
  //       return {
  //         ...homeData,
  //         title: "Total Today Pickup",
  //         number: t,
  //       };
  //     else return eachTitle;
  //   })
  //   setHomeData(updateHomeData)
  // }, [])

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      <div className="dealer__home similar__section">
        <h1 className="similar__section__heading">Dashboard</h1>

        <div className="dashboard">
          {homeData.map((eachData, eachDataIndex) => {
            return (
              <DealerHomeCard
                key={eachDataIndex}
                title={eachData.title}
                number={eachData.number}
              />
            );
          })}
        </div>
      </div>

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerHome;
