import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";

// css
import "../../Css/Cart.css";
import "../../App.css";

// component
import CartTable from "./CartTable";

// redux
import { useDispatch, useSelector } from "react-redux";
import { stepReducerActions } from "../../Redux/stepReducer";
import { cartReducerActions } from "../../Redux/cartReducer";
import axios from "axios";
import { apiUrl } from "../../Private";
import CartView from "./CartView";

const CartStep3 = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const apiKey = JSON.parse(localStorage.getItem("KTMauth"))
  const order = JSON.parse(localStorage.getItem("order_details"))
  const cart = useSelector((state) => state.cartReducer)

  const history = useHistory();

  // check out order initialization
  const checkOut = () => {

    const data = new FormData();
    data.append("customer_id", apiKey['id']);
    data.append("dealer_id", cart.cartItems[0].dealer);
    axios.post(`${apiUrl}/api/add_order/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((res) => {

      const data = new FormData();
      data.append("cart_order_id", res.data.cart_order_id);
      data.append("first_name", order.firstname);
      data.append("last_name", order.lastname);
      data.append("email", apiKey['email']);
      data.append("phone_number", order.phone);
      data.append("address_line_1", order.add_one);
      data.append("address_line_2", order.add_two);
      data.append("city", order.city);
      data.append("state", order.state);
      data.append("country", order.country);
      data.append("pickup_date", order.pickup_date);
      data.append("pickup_time", order.pickup_time);
      data.append("order_note", "zzzz");
      data.append("ip", "123456");

      axios.post(`${apiUrl}/api/order_info/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((res) => {
        console.log(res.data)
        const data = new FormData();
        data.append("order_info_id", res.data.order_info_id);

        axios.post(`${apiUrl}/api/order_confirm/`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(() => {
          setIsOpen(!isOpen);
        })

      }).catch((err) => {
        console.log(err)
      })

    }).catch((err) => {

    })

  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="cart__step">
        <h1>Final Checkout</h1>

        <CartView />

        <div className="final__pickup__address">
          <h1>Pickup Address</h1>
          <div className="pickup__address">
            <div className="left__side">
              <p>
                Name : <span>{order.firstname}</span>
              </p>
              <p>
                Mobile Number : <span>{order.phone}</span>
              </p>
              <p>
                Email ID : <span>{order.email}</span>
              </p>
              <p>
                Address : <span>{order.add_two}</span>
              </p>
              <p>
                State : <span>{order.state}</span>
              </p>
            </div>
            <div className="right__side">
              <p>
                City : <span>{order.city}</span>
              </p>
              <p>
                House no. / Flat no. : <span>{order.add_one}</span>
              </p>
              <p>
                Date : <span>{order.pickup_date}</span>
              </p>
              <p>
                Time Slot : <span>{order.pickup_time}</span>
              </p>
            </div>
          </div>
        </div>

        <button
          className="cart__button"
          onClick={() => {
            dispatch(stepReducerActions.backward("cartStep"));
          }}
        >
          Back
        </button>
        <button
          className="cart__button"
          onClick={checkOut}
        >
          Check out
        </button>

        <Modal
          className="modal__content"
          overlayClassName="modal__overlay"
          isOpen={isOpen}
          ariaHideApp={false}
        >
          <h1>Thanks</h1>
          <p>Your pickup request is placed</p>
          <div>
            <button
              onClick={() => {
                dispatch(cartReducerActions.reset());
                dispatch(stepReducerActions.reset("cartStep"));
                setIsOpen(!isOpen);
                localStorage.removeItem("order_details")
                history.push("/sell");
              }}
            >
              Sell again
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CartStep3;
