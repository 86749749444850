import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0
};

const cartReducer = createSlice({
  name: "cart reducer",
  initialState,
  reducers: {
    add(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].itemQuantity = Number(state.cartItems[itemIndex].itemQuantity) + Number(action.payload.itemQuantity);
        state.cartItems[itemIndex].totalPrice = Number(state.cartItems[itemIndex].itemQuantity) * Number(action.payload.price);
      }
      else {
        // const tempProduct = { ...action.payload, itemQuantity: action.payload.itemQuantity }
        const tempProduct = { ...action.payload }
        state.cartItems.push(tempProduct)
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      // return [...state, action.payload.item];
    },
    remove(state, action) {
      const newState = state.cartItems.filter(
        (eachItem) => eachItem.id !== action.payload.id
      );

      state.cartItems = newState;
      localStorage.setItem("cartItems", JSON.stringify(newState))
      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      // return newState;
      // state.splice(action.payload, 1)
    },
    increment(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].itemQuantity = Number(state.cartItems[itemIndex].itemQuantity) + 1;
        state.cartItems[itemIndex].totalPrice = Number(state.cartItems[itemIndex].itemQuantity) * Number(state.cartItems[itemIndex].price);
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      }
    },
    decrement(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (state.cartItems[itemIndex].itemQuantity > 1) {
        state.cartItems[itemIndex].itemQuantity = Number(state.cartItems[itemIndex].itemQuantity) - 1;
        state.cartItems[itemIndex].totalPrice = Number(state.cartItems[itemIndex].itemQuantity) * Number(state.cartItems[itemIndex].price);
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
      }
    },
    reset(state) {
      // not correct because here new empty array is assigned
      // return state = [];
      state.cartItems = [];
      localStorage.removeItem("cartItems")
      // return [];
    },
  },
});

export const cartReducerActions = cartReducer.actions;
export default cartReducer.reducer;
